import { MouseEvent } from 'react'

import Image from 'next/image'
import Link from 'next/link'

type SubnavLink = {
	icon?: { url?: string }
	text?: string
	LinkHoverColor?: string
	subtext?: string
	link?: {
		href: string
		onClick(event: MouseEvent): void
		target?: '_self' | '_blank'
	}
	badgeText?: string
}

type NavigationContentSingleColumnProps = {
	subtitle?: string
	divider?: boolean
	subnavLinks?: SubnavLink[]
}

export function NavigationContentSingleColumn({
	subtitle,
	divider,
	subnavLinks
}: NavigationContentSingleColumnProps) {
	return (
		<div className="z-10" data-testid="navigation-content-single-column">
			<ul className="shrink-0 min-w-[140px] flex flex-col items-start">
				{subtitle && (
					<li className="uppercase  text-eyebrow mb-2 text-[12px] font-medium">{subtitle}</li>
				)}

				{divider && <div className="bg-gray-1000 h-[1px] w-full"></div>}
				{subnavLinks?.map((subnavLink, i) => (
					<li key={i} className={'group transition duration-100 ease-in-out pt-5'}>
						<Link
							href={subnavLink.link?.href ?? '#'}
							onClick={subnavLink.link?.onClick}
							target={subnavLink.link?.target}
							className="group/link block"
						>
							<span className="flex items-start text-base leading-[19.2px] pb-.5 font-medium text-bc-black link-column-click gap-2 group-hover:text-bc-blue">
								{subnavLink.icon?.url && (
									<Image
										src={
											subnavLink.icon.url.startsWith('//')
												? `https:${subnavLink.icon.url}`
												: subnavLink.icon.url
										}
										alt="icon"
										width="18"
										height="16"
										className="pt-[5px]"
									/>
								)}
								{subnavLink.text && <p className="pt-1">{subnavLink.text}</p>}
								{subnavLink.badgeText && (
									<span className="text-xxs leading-[21px] text-bc-blue bg-[#0D52FF14] rounded-2xl py-0.5 px-2.5 border-[#0D52FF4D] border group-hover:text-white group-hover:border-bc-blue group-hover:bg-bc-blue">
										{subnavLink.badgeText}
									</span>
								)}
							</span>
							{subnavLink.subtext && (
								<div className="mt-2 text-xs text-gray-500 font-normal leading-[18px] link-column-click">
									{subnavLink.subtext}
								</div>
							)}
						</Link>
					</li>
				))}
			</ul>
		</div>
	)
}
