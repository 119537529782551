import { useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import clsx from 'clsx'
import CloseDark from 'src/icons/CloseDark'
import CloseLight from 'src/icons/CloseLight'

type Props = {
	className?: string
	backgroundStyle?: 'solid' | 'gradient'
	ctaBrand?: string
	ctaDetails?: string
	bgColor?: string
	gradientColorStop1?: string
	gradientColorStop2?: string
	gradientDirection?: string
	textColor?: string
	link?: { href?: string; target?: string }
	icon?: { url?: string }
	textAlignment?: string
	hasCloseIcon?: boolean
	closeIconColor?: 'light' | 'dark'
}

export function Banner({
	className,
	backgroundStyle,
	ctaBrand,
	ctaDetails,
	bgColor,
	gradientColorStop1,
	gradientColorStop2,
	gradientDirection,
	textColor,
	link,
	icon,
	textAlignment,
	hasCloseIcon,
	closeIconColor
}: Props) {
	const [isVisible, setIsVisible] = useState(true)

	const bgStyle =
		backgroundStyle === 'solid'
			? { backgroundColor: bgColor }
			: backgroundStyle === 'gradient'
			? {
					background: `linear-gradient(${gradientDirection}, ${gradientColorStop1}, ${gradientColorStop2})`
			  }
			: {}

	if (!isVisible) return null

	return (
		<div className="pointer-events-none w-full z-10" data-testid="banner">
			<div
				className={clsx(
					'pointer-events-auto flex items-center justify-between gap-x-6 sm:rounded-xl',
					className
				)}
				style={bgStyle}
			>
				<Link
					href={link?.href ?? ''}
					target={link?.target}
					className="text-xs leading-6 flex items-center justify-between w-full md:text-[16px]"
				>
					<div className={clsx('flex', textAlignment)} style={{ color: textColor }}>
						<div className=" no-underline hover:underline">
							<strong className="font-semibold">{ctaBrand}</strong>
							{ctaDetails && (
								<span className="hidden md:inline">
									<svg
										viewBox="0 0 2 2"
										className="mx-2 inline h-0.5 w-0.5 fill-current"
										aria-hidden="true"
									>
										<circle cx={1} cy={1} r={1} />
									</svg>
									{ctaDetails}
								</span>
							)}
						</div>
						{icon?.url ? (
							<span className="ml-3 self-center">
								<Image
									src={icon.url.startsWith('//') ? `https:${icon.url}` : icon.url}
									alt="icon"
									width="16"
									height="24"
								/>
							</span>
						) : (
							<span className="ml-2" style={{ color: textColor }}>
								&rarr;
							</span>
						)}
					</div>
					{hasCloseIcon && (
						<button
							className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
							type="button"
							onClick={() => setIsVisible(false)}
						>
							<span>{closeIconColor === 'dark' ? <CloseDark /> : <CloseLight />}</span>
						</button>
					)}
				</Link>
			</div>
		</div>
	)
}
