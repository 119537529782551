import * as React from 'react'
import { SVGProps } from 'react'

const CloseLight = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_8_78"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="16"
			height="16"
		>
			<rect width="16" height="16" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_8_78)">
			<path
				d="M4.85 12L4 11.15L7.15 8L4 4.85L4.85 4L8 7.15L11.15 4L12 4.85L8.85 8L12 11.15L11.15 12L8 8.85L4.85 12Z"
				fill="white"
			/>
		</g>
	</svg>
)

export default CloseLight
